import { Deserializable } from './deserialize';
import { IOriginalArticlePrices } from "./articleOption";
import {IContingent} from "./contingent";
import {IUserGroup} from "./userGroup";
export type TContingentType = "promomaterial"|"handsample"|"samplesNotUnderAMG"|"promotionalMaterials";


export interface IArticle {
  alwaysInStock?: boolean;
  alwaysOnStock?: boolean;
  showArticle?: boolean;
  articleType?: TContingentType;
  token?: number;
  optionText?: string;
  additionalOptions?: any;
  selectedAdditionalOptions?: any;
  deliveryTime?: number;
  characterCount?: number;
  selected?: boolean;
  category?: string[];
  connectId?: number;
  articleId?:number,
  quantity?: number;
  stateQuantity?: number;
  createdAt?: string;
  deletedAt?: string;
  description?: string;
  imageDate?: string;
  additionalInformation?: string;
  amount?: number;
  id: string;
  _id?: string;
  imageSourceUrl?: string;
  mainImage?: string;
  contingent?:IContingent;
  name?: string;
  userGroupIds?: string[];
  userGroup?: IUserGroup[];
  userGroupNames?: string[];
  title?: string;
  previewUrl?: string;
  packagingAmount?: number;
  price?: number;
  images?: string[];
  customerPrice?: IOriginalArticlePrices[];
  industryPrice?: IOriginalArticlePrices[];
  stock?: number;
  artikelText?: {
    "de": {
      "name": string,
      "description": string
    }
  };
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updatedAt?: string;
  hasOptionSelected?: boolean;
  minimumOrderQuantity?: number;
  maximumOrderQuantity?: number;
  quantityGrouping?: boolean;
  virtual?: boolean;
  selectedOption?: any;
  value?: number;
  vat?: number;
  collection?: any;
  fileStackId?: string;
  summarizeOptions?: any;
}

export class Article implements IArticle, Deserializable {
  alwaysInStock?: boolean
  alwaysOnStock?: boolean;
  showArticle?: boolean;
  articleType?: TContingentType;
  token?: number;
  optionText?: string;
  additionalOptions?: any;
  selectedAdditionalOptions?: any;
  deliveryTime?: number;
  characterCount?: number;
  selected?: boolean;
  category?: string[];
  connectId?: number;
  articleId?:number;
  quantity?: number;
  createdAt?: string;
  deletedAt?: string;
  description?: string;
  imageDate?: string;
  additionalInformation?: string;
  amount?: number;
  id: string;
  _id?: string;
  imageSourceUrl?: string;
  mainImage?: string;
  contingent?:IContingent;
  name?: string;
  userGroupIds?: string[];
  userGroup?: IUserGroup[];
  userGroupNames?: string[];
  title?: string;
  previewUrl?: string;
  packagingAmount?: number;
  price?: number;
  images?: string[];
  customerPrice?: IOriginalArticlePrices[];
  industryPrice?: IOriginalArticlePrices[];
  stock?: number;
  artikelText?: {
    "de": {
      "name": string,
      "description": string
    }
  };
  totalPrice?: number;
  totalAmount?: number;
  type?: string;
  options?: any;
  selectedOptions?: any;
  updatedAt?: string;
  hasOptionSelected?: boolean;
  minimumOrderQuantity?: number;
  maximumOrderQuantity?: number;
  quantityGrouping?: boolean;
  virtual?: boolean;
  selectedOption?: any;
  value?: number;
  vat?: number;
  collection?: any;
  fileStackId?: string;
  summarizeOptions?: any;
  deserializeInput(input: any) {
    this.name = String(input.name);
    this.description = String(input.description);
    this.connectId =Number(input.connectId);
    this.showArticle = Boolean(input.showArticle);
    this.price = Number(input.price);
    this.maximumOrderQuantity =900;
    this.category = input.category.map(String);  // Assuming all categories are string IDs
    this.additionalInformation = input.additionalInformation
    this.userGroupIds = input.userGroupIds?.map(String);
    this.articleType = input.articleType
    this.options = input.options; // Handle complex objects if necessary
    this.previewUrl = String(input.previewUrl);
    this.images = input.images.map(String);

    return this;
  }
  deserialize(input: IArticle) {
    Object.assign(this, input);
    this.virtual = (input.type == "Virtuell");
    this.hasOptionSelected = (!input?.options);
    this.id = input.id;
    return this;
  }
}
